import { depositsMutations } from './types'
import Vue from 'vue'

export default {
  [depositsMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [depositsMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [depositsMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [depositsMutations.UPDATE_LIST_ITEM] (state, payload = {}) {
    if (!payload.id) return
    const index = state.list.findIndex(el => el.id === payload.id)

    if (index < 0) return
    const newItem = state.list[index]

    if (payload.state) {
      if (!payload.depositIds) {
        newItem.deposits = newItem.deposits
          .map(el => ({ ...el, state: payload.state }))
      } else {
        newItem.deposits = newItem.deposits
          .map(el => {
            if (payload.depositIds.includes(el.id)) {
              return { ...el, state: payload.state }
            }
            return el
          })
      }
    }

    Vue.set(state.list, index, newItem)
  },

  [depositsMutations.UPDATE_LIST_ITEMS] (state, payload = {}) {
    if (!payload.leadId) return

    const targetIds = state.list
      .filter(el => el.leadId === payload.leadId)
      .map(el => el.id)

    for (const id of targetIds) {
      const index = state.list.findIndex(el => el.id === id)
      const newItem = state.list[index]

      if (payload.broker) {
        newItem.broker = payload.broker
      }

      Vue.set(state.list, index, newItem)
    }
  },

  [depositsMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [depositsMutations.SET_TOTAL_COUNT] (state, value) {
    state.totalCount = value
  },

  [depositsMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },
}
