export function genDepositsState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    totalCount: 0,
    fetchNext: null, // function
  }
}

export default genDepositsState
