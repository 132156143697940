import { depositsActions, depositsMutations, depositsGetters } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { DEPOSITS_PAGE_LIMIT } from '../constants'
import omitBy from 'lodash/omitBy'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { userGetters } from 'Store/entities/User/types'
import { compareAlphabetically, fieldComparator } from 'Utils/arrayHelpers'
import { rootGet } from 'Store/helpers/rootHelpers'
import { enums } from '@shelf.network/js-sdk'

const loneSdkCall = new LoneSdkCall()

export default {
  async [depositsActions.LOAD_ACTIVE_DEPOSITS] ({ commit }, payload = {}) {
    commit(depositsMutations.SET_IS_LOADING, true)

    payload.filter = payload.filter || {}
    payload.filter.hasActiveDeposit = true
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getLeads(payload)
    )

    commit(depositsMutations.SET_LIST, mapDepositsEx(data))
    commit(depositsMutations.SET_TOTAL_COUNT, data.length)
    commit(depositsMutations.SET_IS_LOADING, false)
  },

  async [depositsActions.LOAD_HISTORY] ({ commit }, payload = {}) {
    commit(depositsMutations.SET_IS_LOADING, true)

    payload.include = [
      'identity',
      'identity.basics',
      'identity.customer',
      'identity.customer.lead',
      'identity.customer.lead.broker',
    ].join(',')
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data, fetchNext, meta = {} } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getDeposits(payload)
    )

    commit(depositsMutations.SET_LIST, mapDepositsEx(data))
    commit(depositsMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(depositsMutations.SET_FETCH_NEXT, fetchNext)
    commit(depositsMutations.SET_IS_LOADING, false)
  },

  async [depositsActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[depositsGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(depositsMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await loneSdkCall.takeLatest(
      fetchNext()
    )
    commit(depositsMutations.PUSH_LIST, mapDepositsEx(data))
    commit(depositsMutations.SET_FETCH_NEXT, newFetchNext)

    commit(depositsMutations.SET_IS_NEXT_LOADING, false)
  },

  async [depositsActions.SET_DEPOSITS_STATES] ({ commit, getters }, pl = {}) {
    const { depositIds, state } = pl

    const list = getters[depositsGetters.LIST_RAW]
    const depId = depositIds[0]
    const item = list.find(el => el.deposits.some(dep => dep.id === depId))
    const itemId = item.id

    const promises = []
    for (const depId of depositIds) {
      const createPromise = async () => {
        const opts = { id: depId, attributes: { state } }
        await sdk.buyingPower.patchDeposit(opts)

        const payload = { id: itemId, depositIds: [depId], state }
        commit(depositsMutations.UPDATE_LIST_ITEM, payload)
      }
      promises.push(createPromise())
    }
    return Promise.all(promises)
  },

  async [depositsActions.CHARGE_DEPOSIT] ({ commit, getters }, pl = {}) {
    const { depositId, amount } = pl
    const state = enums.depositStates.feeCharged

    await sdk.buyingPower.patchDeposit({
      id: depositId,
      attributes: { state, amount }
    })

    const listItem = getters[depositsGetters.LIST_RAW]
      .find(el => el.deposits.some(dep => dep.id === depositId))

    commit(depositsMutations.UPDATE_LIST_ITEM, {
      id: listItem.id,
      depositIds: [depositId],
      state
    })
  }
}

function ensureRequiredFilters (payload) {
  const result = Object.assign({}, payload)
  result.filter = Object.assign({}, payload.filter)

  result.filter.platform = result.filter.platform ||
    rootGet(`entities/user/${userGetters.PLATFORM_ID}`)

  if (rootGet(`entities/user/${userGetters.IS_BROKER}`)) {
    result.filter.broker = rootGet(`entities/user/${userGetters.ACCOUNT_ID}`)
  }

  return result
}

function normalizePayload (payload) {
  const result = Object.assign({}, payload)
  result.page = Object.assign({}, payload.page)

  result.page.limit = DEPOSITS_PAGE_LIMIT

  return omitBy(result, val => !val)
}

function mapDepositsEx (array = []) {
  const firstItem = array[0] || {}

  if (firstItem.resourceType === 'deposits') return mapDeposits(array)
  if (firstItem.resourceType === 'leads') return mapLeads(array)
  return []
}

function mapDeposits (array) {
  return array.map(el => {
    const r11s = el.relationships || {}
    const identity = r11s.identity || {}
    const customer = (identity.relationships || {}).customer || {}
    const lead = (customer.relationships || {}).lead || {}
    const broker = (lead.relationships || {}).broker || {}

    return {
      id: el.id,
      leadId: lead.id,
      customer,
      broker,
      deposits: [el],
    }
  })
}

function mapLeads (array) {
  return array.map(el => {
    const r11s = el.relationships || {}
    const broker = r11s.broker || {}
    const customer = r11s.customer || {}
    const customerIdentity = (customer.relationships || {}).identity || {}
    const deposits = (customerIdentity.relationships || {}).activeDeposits || []

    customer.relationships = customer.relationships || {}
    customer.relationships.lead = el

    deposits.sort(fieldComparator('id', compareAlphabetically, '-'))

    return {
      id: el.id,
      leadId: el.id,
      customer,
      broker,
      deposits,
    }
  })
}
