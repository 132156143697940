import { depositsGetters } from './types'
import { DepositEntry } from '../models/DepositEntry'

export default {
  [depositsGetters.IS_LOADING]: state => state.isLoading,

  [depositsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [depositsGetters.LIST]: state => state.list
    .map(item => new DepositEntry(item)),

  [depositsGetters.LIST_RAW]: state => state.list,

  [depositsGetters.LIST_COUNT]: state => state.totalCount || 0,

  [depositsGetters.FETCH_NEXT]: state => state.fetchNext,
}
